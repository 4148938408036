@charset "utf-8";

/*--------------------------------------------------------
  RECRUIT
----------------------------------------------------------*/
.recruit-message {
  @include base-wrap--space();
  margin-top: 60px;
  font-size: 16px;
  text-align: center;
  @include mq(sp) {
    @include fz_vw(14);
  }
}
.recruit-requirements {
  @include base-wrap--space();
  .title-custom {
    font-size: 40px;
    @include mq(sp) {
      @include fz_vw(36);
    }
  }
  &__text {
    margin: 30px 0;
    a {
      display: inline-block;
      padding: 0 5px;
      color: $main;
      text-decoration: underline;
    }
  }
}