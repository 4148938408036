@charset "UTF-8";
/* 黒 */
/* グレー */
/* 薄いグレー */
/* ブルー */
/* オレンジ */
/* smartphone font-size */
/* media quary */
/* flex */
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  line-height: 1;
  font-size: 14px;
}

@media screen and (max-width: 599px) {
  body {
    font-size: 14px;
    font-size: 3.73333vw;
  }
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  padding-top: 84px;
  /*font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;*/
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #231815;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  line-height: 1.8;
  overflow-x: hidden;
}

@media screen and (max-width: 959px) and (min-width: 599px) {
  body {
    width: 1024px;
  }
}

@media screen and (min-width: 960px) {
  body {
    min-width: 1024px;
  }
}

@media screen and (max-width: 599px) {
  body {
    padding-top: 54px;
  }
}

a {
  color: #231815;
  text-decoration: none;
  transition: .2s;
}

a:hover {
  transition: .2s;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

a:hover img {
  opacity: 0.9;
}

.text {
  line-height: 1.8;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clear {
  clear: both;
}

/* show only  SP/PC
-------------------------------*/
@media screen and (min-width: 960px) {
  .sp {
    display: none !important;
  }
}

@media screen and (max-width: 959px) and (min-width: 599px) {
  .sp {
    display: none !important;
  }
}

@media screen and (min-width: 960px) {
  .sp-s {
    display: none !important;
  }
}

@media screen and (max-width: 959px) and (min-width: 599px) {
  .sp-s {
    display: none !important;
  }
}

@media screen and (max-width: 599px) {
  .sp-s {
    display: block !important;
  }
}

@media screen and (max-width: 959px) and (min-width: 599px) {
  .pc {
    display: block !important;
  }
}

@media screen and (max-width: 599px) {
  .pc {
    display: none !important;
  }
}

@media screen and (max-width: 959px) and (min-width: 599px) {
  .pc-inline {
    display: inline !important;
  }
}

@media screen and (max-width: 599px) {
  .pc-inline {
    display: none !important;
  }
}

@media screen and (max-width: 959px) and (min-width: 599px) {
  .pc-s {
    display: block !important;
  }
}

@media screen and (max-width: 599px) {
  .pc-s {
    display: none !important;
  }
}

/* @group float
-------------------------------- */
.right {
  float: right;
}

.left {
  float: left;
}

.img-left {
  float: left;
  margin: 0 20px 10px 0;
}

.img-right {
  float: right;
  margin: 0 0 10px 20px;
}

/* @text-align
-------------------------------- */
.ta-right {
  text-align: right !important;
}

.ta-center {
  text-align: center !important;
}

.ta-left {
  text-align: left !important;
}

/* @vertical-align
-------------------------------- */
.va-top {
  vertical-align: top !important;
}

.va-bottom {
  vertical-align: bottom !important;
}

.va-middle {
  vertical-align: middle !important;
}

/* @group color
-------------------------------- */
.red {
  color: #EA5413 !important;
}

.blue {
  color: #0099ff !important;
}

.gray {
  color: #666 !important;
}

.pink {
  color: #C60070 !important;
}

.peach {
  color: #F86363 !important;
}

.green {
  color: #89B929 !important;
}

/* @group margin
-------------------------------- */
.m0 {
  margin: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

.mt0 {
  margin-top: 0px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.ml40 {
  margin-left: 40px !important;
}

/* @font size
-------------------------------- */
.fs10 {
  font-size: 10px !important;
}

.fs11 {
  font-size: 11px !important;
}

.fs12 {
  font-size: 12px !important;
}

.fs13 {
  font-size: 13px !important;
}

.fs14 {
  font-size: 14px !important;
}

.fs15 {
  font-size: 15px !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs17 {
  font-size: 17px !important;
}

.fs18 {
  font-size: 18px !important;
}

.fs19 {
  font-size: 19px !important;
}

.fs20 {
  font-size: 20px !important;
}

.fs21 {
  font-size: 21px !important;
}

.fs22 {
  font-size: 22px !important;
}

.fs23 {
  font-size: 23px !important;
}

.fs24 {
  font-size: 24px !important;
}

.fs25 {
  font-size: 25px !important;
}

.fs26 {
  font-size: 26px !important;
}

.fs27 {
  font-size: 27px !important;
}

.fs28 {
  font-size: 28px !important;
}

.fs29 {
  font-size: 29px !important;
}

.fs30 {
  font-size: 30px !important;
}

.fs31 {
  font-size: 31px !important;
}

.fs32 {
  font-size: 32px !important;
}

.fs34 {
  font-size: 34px !important;
}

.fs36 {
  font-size: 36px !important;
}

.fs38 {
  font-size: 38px !important;
}

.fs48 {
  font-size: 48px !important;
}

.fs56 {
  font-size: 56px !important;
}

.fs64 {
  font-size: 64px !important;
}

/* @font-weight
-------------------------------- */
.fw-bold {
  font-weight: bold !important;
}

/* @border
-------------------------------- */
.bb-none {
  border-bottom: 0 !important;
}

.bl-none {
  border-left: 0 !important;
}

.br-none {
  border-right: 0 !important;
}

.bt-none {
  border-top: 0 !important;
}

/* @status font color
-------------------------------- */
.required {
  color: #c00;
}

.error-message {
  color: #c00;
}

/* @wp image
-------------------------------- */
.alignleft {
  float: left;
  margin: 0 20px 10px 0;
}

.alignright {
  float: right;
  margin: 0 0 10px 20px;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* @flex
-------------------------------- */
.flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 599px) {
  .flex {
    display: block;
    width: auto;
  }
}

.flex-sp {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

/* @font-family
-------------------------------- */
.mincho {
  font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.yu {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.contents {
  margin-bottom: 100px;
}

.contents--default {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .contents--default {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.base-wrap {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .base-wrap {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

.base-wrap--space {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .base-wrap--space {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.main-wrap {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px;
}

@media screen and (max-width: 599px) {
  .main-wrap {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.main-wrap--space {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px;
}

@media screen and (max-width: 599px) {
  .main-wrap--space {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.screen-reader-text {
  display: none;
}

/*--------------------------------------------------------
  ヘッダー
----------------------------------------------------------*/
/* ヘッダー */
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  border-bottom: 1px solid #ccc;
  background: rgba(255, 255, 255, 0.7);
  transition: .5s;
}

.header:before, .header:after {
  content: "";
  display: table;
}

.header:after {
  clear: both;
}

.header.hide {
  transform: translateY(-100%);
}

.header__inner {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .header__inner {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

.header__logo {
  float: left;
  margin: 30px 0;
}

@media screen and (max-width: 599px) {
  .header__logo {
    float: none;
    margin: 14px 0 14px 50px;
    text-align: center;
  }
}

.header__logo h1 {
  display: inline;
}

.header__logo img {
  width: 80%;
  height: 18px;
}

.gnavi {
  float: right;
}

@media screen and (max-width: 599px) {
  .gnavi {
    position: fixed;
    top: 0;
    left: -280px;
    float: none;
    height: 100%;
    background: #333;
    z-index: 800;
  }
}

.gnavi__list:before, .gnavi__list:after {
  content: "";
  display: table;
}

.gnavi__list:after {
  clear: both;
}

@media screen and (max-width: 599px) {
  .gnavi__list {
    padding-top: 80px;
  }
}

.gnavi__list__item {
  float: left;
}

@media screen and (max-width: 599px) {
  .gnavi__list__item {
    float: none;
  }
}

.gnavi__list__item a {
  display: block;
  padding: 30px 26px;
  font-family: "Josefin Slab", serif;
}

@media screen and (max-width: 599px) {
  .gnavi__list__item a {
    padding: 15px 30px;
    color: #fff;
  }
}

@media screen and (min-width: 960px) {
  .gnavi__list__item a:hover {
    background: #ccc;
  }
}

/*--------------------------------------------------------
  フッター
----------------------------------------------------------*/
.footer {
  background: #333;
  color: #fff;
}

.footer a {
  color: #fff;
}

.footer__inner {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  position: relative;
  padding: 60px 0;
}

@media screen and (max-width: 599px) {
  .footer__inner {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 599px) {
  .footer__inner {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 599px) {
  .footer__inner {
    padding: 30px 0 0;
  }
}

.footer__scissor {
  position: absolute;
  top: -27px;
  left: 20px;
}

@media screen and (max-width: 599px) {
  .footer__scissor {
    top: -12px;
    width: 45px;
    height: 28px;
  }
}

.footer__info {
  width: 340px;
}

@media screen and (max-width: 599px) {
  .footer__info {
    width: auto;
    margin-bottom: 30px;
    text-align: center;
  }
}

.footer__info__name {
  font-size: 16px;
  margin-bottom: 8px;
}

@media screen and (max-width: 599px) {
  .footer__info__name {
    font-size: 16px;
    font-size: 4.26667vw;
  }
}

.footer__info__address {
  font-size: 13px;
  color: #B1B1B1;
}

@media screen and (max-width: 599px) {
  .footer__info__address {
    font-size: 13px;
    font-size: 3.46667vw;
  }
}

.footer__info__pass {
  color: #B1B1B1;
  font-size: 13px;
}

@media screen and (max-width: 599px) {
  .footer__info__pass {
    font-size: 13px;
    font-size: 3.46667vw;
  }
}

.fnavi {
  font-size: 15px;
  width: 400px;
  padding-left: 40px;
  border-left: 1px solid #686868;
}

@media screen and (max-width: 599px) {
  .fnavi {
    font-size: 15px;
    font-size: 4vw;
  }
}

@media screen and (max-width: 599px) {
  .fnavi {
    display: none;
    width: auto;
    margin-bottom: 30px;
    padding-left: 30px;
    border-left: none;
  }
}

.fnavi a {
  position: relative;
  display: inline-block;
}

.fnavi a::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background: #fff;
  transition: .5s;
}

@media screen and (min-width: 960px) {
  .fnavi a:hover::after {
    width: 100%;
  }
}

.copyright {
  font-size: 12px;
  padding-top: 60px;
  color: #B1B1B1;
}

@media screen and (max-width: 599px) {
  .copyright {
    font-size: 12px;
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 599px) {
  .copyright {
    padding: 20px 0;
    background: #fff;
    text-align: center;
  }
}

/* ページ上へ戻る */
#TopLink {
  position: fixed;
  bottom: 50px;
  left: 50%;
  z-index: 10000;
  width: 70px;
  height: 70px;
  margin-left: 480px;
  border-radius: 50%;
  background: #666;
  text-align: right;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.8;
}

@media screen and (max-width: 599px) {
  #TopLink {
    display: none;
  }
}

#TopLink::before {
  position: absolute;
  top: 24px;
  left: 50%;
  content: "";
  display: block;
  width: 1px;
  height: 24px;
  margin-left: -1px;
  background: #fff;
}

#TopLink::after {
  position: absolute;
  top: 22px;
  left: 50%;
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  margin-left: -8px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(-45deg);
}

#TopLink a {
  display: none;
}

.bnavi {
  display: none;
}

@media screen and (max-width: 599px) {
  .bnavi {
    display: block;
  }
}

.bnavi__list__item a {
  display: block;
  padding: 10px 0;
  text-align: center;
}

.bnavi__list__item:nth-child(odd) {
  background: #EBEBEB;
}

/*--------------------------------------------------------
	サイドナビ
----------------------------------------------------------*/
/* ローカルナビ */
.widget-local-navi > ul {
  margin: 0 -2px;
  padding: 0 2px 15px;
}

.widget-local-navi > ul > li {
  border-bottom: 1px dotted #ccc;
}

.widget-local-navi li:last-child {
  border-bottom: none;
}

.widget-local-navi li a {
  display: block;
  padding: 16px 10px 16px 16px;
  text-decoration: none;
  color: #333;
}

.widget-local-navi li ahover {
  color: #CC3300;
}

/* ローカルナビ2層 */
.widget-local-navi ul ul {
  margin-left: 16px;
  margin-bottom: 10px;
}

.widget-local-navi ul ul li a {
  padding: 0px 10px 5px 20px;
  font-size: 13px;
}

/* ウィジェット共通 */
.widget {
  margin-bottom: 50px;
}

.widget h2 {
  margin-bottom: 20px;
  padding: 10px 15px;
  background: #231815;
  color: #fff;
  font-size: 15px;
}

.widget img {
  max-width: 100%;
}

.widget ul li {
  position: relative;
  margin-left: 5px;
  margin-bottom: 10px;
  padding-left: 15px;
}

.widget ul li::before {
  position: absolute;
  top: 14px;
  left: 0;
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #231815;
}

.widget ul li a {
  color: #333333;
  text-decoration: none;
}

/* ブログウィジェット */
.blog-widget li {
  padding: 10px 0;
  border-bottom: 1px dashed #ccc;
  line-height: 1.2;
}

.blog-widget li a {
  color: #1D4C86;
  text-decoration: none;
}

.blog-widget li a:hover {
  color: #FC4600;
  text-decoration: underline;
}

/* ブログ カレンダー */
.widget-blog-calendar table {
  border-collapse: collapse;
  box-sizing: border-box;
}

.widget-blog-calendar table th {
  padding: 6px 0;
  border: 1px solid #003C9F;
  font-size: 12px;
}

.widget-blog-calendar table td {
  padding: 6px 0;
  color: #aaa;
  text-align: center;
  border: 1px solid #003C9F;
  font-size: 12px;
}

.widget-blog-calendar table a {
  color: #FC4600;
  text-decoration: underline;
}

.widget-blog-calendar table center {
  color: #000;
  font-size: 16px;
}

/*--------------------------------------------------------
  スマホナビゲーション
----------------------------------------------------------*/
/* スマホグローバルナビ開閉ボタン */
@media screen and (max-width: 599px) {
  .nav-sp {
    display: none;
  }
}

.header__menu {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 599px) {
  .header__menu {
    display: block;
    z-index: 1000;
  }
}

#panel-btn {
  display: inline-block;
  position: relative;
  width: 53px;
  height: 53px;
  background: #FC4600;
  text-decoration: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#panel-btn .close {
  background: transparent;
}

#panel-btn .close::before, #panel-btn .close::after {
  margin-top: 0;
  background: #fff;
}

#panel-btn .close::before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#panel-btn .close::after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

#panel-btn-icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 2px;
  margin: -2px 0 0 -15px;
  background: #fff;
  -webkit-transition: .2s;
  transition: .2s;
}

#panel-btn-icon::before, #panel-btn-icon::after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 2px;
  background: #fff;
  -webkit-transition: .3s;
  transition: .3s;
}

#panel-btn-icon::before {
  margin-top: -12px;
}

#panel-btn-icon::after {
  margin-top: 10px;
}

#BtnClose {
  display: none;
  padding: 20px 0 20px;
  background: #efefef;
  color: #003C9F;
  font-weight: bold;
  letter-spacing: .1em;
  text-align: center;
  font-size: 20px;
  font-size: 5.33333vw;
}

@media screen and (max-width: 599px) {
  #BtnClose {
    display: block;
  }
}

.nav-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  display: none;
  width: 100%;
  height: 100%;
  background: #231815;
  opacity: 0;
}

/* ローディングの背景部分のCSS */
.loader {
  background: #fff;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}

/* ローディングのアニメーション部分のCSS (https://projects.lukehaas.me/css-loaders/) */
.loader-animation,
.loader-animation:before,
.loader-animation:after {
  background: #003C9F;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader-animation {
  height: 10px;
  left: 50%;
  margin: -5px 0 0 -5px;
  position: absolute;
  top: 50%;
  width: 10px;
  color: #003C9F;
  text-indent: -9999em;
  font-size: 10px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader-animation:before,
.loader-animation:after {
  position: absolute;
  top: 0;
  content: '';
}

.loader-animation:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader-animation:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.loader-logo {
  position: relative;
  display: block;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 30px;
  border-bottom: 1px solid #FC4600;
  margin-left: -70px;
  margin-top: 70px;
  background: url(../img/common/logo.svg) no-repeat;
  background-size: 150px 20px;
}

.js-scroll-fade {
  position: relative;
  opacity: 0;
  transform: translate(0, 40px);
  transition: all 1s cubic-bezier(0.155, 0.91, 0.695, 0.95);
}

.js-scroll-fade.is-animation {
  opacity: 1;
  transform: translate(0, 0);
}

/*--------------------------------------------------------
	ボタン
----------------------------------------------------------*/
.button {
  position: relative;
  display: inline-block;
  margin: 10px 0;
  padding: 10px 70px;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 599px) {
  .button {
    font-size: 14px;
    font-size: 3.73333vw;
    padding: 10px 70px;
  }
}

.button::before {
  position: absolute;
  left: -28px;
  top: 50%;
  content: "";
  display: block;
  width: 57px;
  height: 1px;
  background: #fff;
  transition: .3s;
}

.button::after {
  position: absolute;
  left: 23px;
  top: 50%;
  content: "";
  display: block;
  width: 9px;
  height: 1px;
  margin-top: -3px;
  background: #fff;
  transform: rotate(45deg);
  transition: .3s;
}

@media screen and (min-width: 960px) {
  .button:hover {
    text-decoration: none;
  }
  .button:hover::before {
    width: 80px;
    transition: .3s;
  }
  .button:hover::after {
    left: 46px;
    transition: .3s;
  }
}

.button--main {
  background-color: #003C9F;
  font-size: 18px;
}

.btn-wrap {
  text-align: center;
}

.btn-wrap a {
  display: inline-block;
}

/*--------------------------------------------------------
  タイトル
----------------------------------------------------------*/
/* 見出し（デフォルト下層h1用） */
.title-main {
  position: relative;
  display: inline-block;
  margin-bottom: 60px;
  padding-bottom: 30px;
  font-family: "Josefin Slab", serif;
  font-size: 62px;
  font-weight: normal;
  line-height: 1.2;
}

@media screen and (max-width: 599px) {
  .title-main {
    font-size: 36px;
    font-size: 9.6vw;
    padding-bottom: 15px;
  }
}

.title-main::before {
  position: absolute;
  top: -6px;
  right: -130px;
  content: "";
  display: block;
  width: 86px;
  height: 93px;
  background: url(../img/page/icn_title.png) no-repeat;
}

@media screen and (max-width: 599px) {
  .title-main::before {
    top: auto;
    bottom: -30px;
    right: 0;
    width: 50px;
    height: 54px;
    background-size: 50px 54px;
  }
}

.title-main::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  content: "";
  display: block;
  width: 120px;
  height: 1px;
  margin-left: -60px;
  background: #FC4600;
}

/* 下層ページの見出し（WYSIWYG用） */
.page h2 {
  margin-bottom: 30px;
  font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 30px;
  font-weight: normal;
  line-height: 1.3;
}

@media screen and (max-width: 599px) {
  .page h2 {
    font-size: 18px;
    font-size: 4.8vw;
  }
}

.page h3 {
  margin-bottom: 20px;
  border-bottom: 4px solid #FC4600;
  color: #003C9F;
  font-size: 24px;
}

@media screen and (max-width: 599px) {
  .page h3 {
    font-size: 16px;
    font-size: 4.26667vw;
  }
}

.page h3 a {
  text-decoration: none;
  color: #003C9F;
}

.page h3 a:hover {
  text-decoration: underline;
  color: #888;
}

.page h4 {
  margin: 30px 0 20px;
  font-size: 18px;
  font-weight: bold;
}

.page h5 {
  margin-top: 20px;
  font-size: 18px;
}

/* 見出し（カスタム下層用） */
.title-custom {
  position: relative;
  margin-top: 100px;
  margin-bottom: 60px;
  padding-bottom: 30px;
  font-family: "Josefin Slab", serif;
  font-size: 62px;
  font-weight: normal;
  line-height: 1.2;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .title-custom {
    font-size: 36px;
    font-size: 9.6vw;
    margin-top: 50px;
    padding-bottom: 15px;
  }
}

.title-custom::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  content: "";
  display: block;
  width: 120px;
  height: 1px;
  margin-left: -60px;
  background: #FC4600;
}

/*--------------------------------------------------------
  ぱんくず
----------------------------------------------------------*/
.breadcrumbs {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin: 20px auto 30px;
  padding: 0 10px;
  font-size: 13px;
  box-sizing: border-box;
}

@media screen and (max-width: 599px) {
  .breadcrumbs {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 599px) {
  .breadcrumbs {
    display: none;
  }
}

/*--------------------------------------------------------
  ページネーション
----------------------------------------------------------*/
/* ブログ　pagination */
.pagination {
  margin: 60px 0;
  text-align: center;
  font-size: 12px;
  font-size: 1.2rem;
}

.pagination span.disabled,
.pagination span.prev a,
.pagination span.next a {
  margin: 0 20px;
  color: #333;
}

.pagination span.current,
.pagination span.number a {
  margin: 0 4px;
  padding: 7px 10px;
  background: #dedede;
  color: #fff;
  text-decoration: none !important;
}

.pagination span.current,
.pagination span.number a:hover {
  background: #666;
}

.pagination a:active {
  background: #666;
}

/*--------------------------------------------------------
  コンテンツナビ
----------------------------------------------------------*/
.contents-navi {
  margin: 50px 0;
  text-align: center;
}

.contents-navi a {
  display: inline-block;
  padding: 11px 14px;
  color: #000;
}

.contents-navi a.prev-link, .contents-navi a.next-link {
  background: #ddd;
}

.contents-navi a:hover {
  color: #333;
}

.contents-navi a:hover.prev-link, .contents-navi a:hover.next-link {
  background: #eee;
}

/*--------------------------------------------------------
  ページ上部へ戻るボタン
----------------------------------------------------------*/
.page-top {
  background: #999999;
  text-align: center;
  cursor: pointer;
}

/*--------------------------------------------------------
  テーブル
----------------------------------------------------------*/
.contents table {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-top: 1px solid #ccc;
}

@media screen and (max-width: 599px) {
  .contents table {
    border: 0;
  }
}

.contents table tr {
  border-bottom: 1px solid #ccc;
}

@media screen and (max-width: 599px) {
  .contents table tr {
    border: 0;
  }
}

.contents table th {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  border-right: 1px solid #ccc;
  color: #231815;
  font-size: 14px;
  text-align: left;
  line-height: 1.5;
  vertical-align: top;
}

@media screen and (max-width: 599px) {
  .contents table th {
    font-size: 14px;
    font-size: 3.73333vw;
    display: block;
    width: 100%;
    padding: 15px 10px;
    border-right: 0;
    border-bottom: 1px solid #ccc;
  }
}

.contents table td {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  font-size: 14px;
  line-height: 1.5;
}

@media screen and (max-width: 599px) {
  .contents table td {
    font-size: 14px;
    font-size: 3.73333vw;
    display: block;
    width: 100%;
    padding: 15px 10px 30px;
  }
}

.table-scroll {
  overflow: auto;
  white-space: nowrap;
}

.table-scroll::-webkit-scrollbar {
  height: 5px;
}

.table-scroll::-webkit-scrollbar-track {
  background: #F1F1F1;
}

.table-scroll::-webkit-scrollbar-thumb {
  background: #BCBCBC;
}

@media screen and (max-width: 599px) {
  .table-scroll th {
    font-size: 14px;
    font-size: 3.73333vw;
    display: table-cell !important;
    width: auto !important;
    padding: 10px !important;
  }
}

@media screen and (max-width: 599px) {
  .table-scroll td {
    font-size: 14px;
    font-size: 3.73333vw;
    display: table-cell !important;
    width: auto !important;
    padding: 10px !important;
  }
}

/*--------------------------------------------------------
	icon
----------------------------------------------------------*/
/*--------------------------------------------------------
	リスト
----------------------------------------------------------*/
.page ul {
  margin: 10px 0 30px 1em;
}

.page ul > li {
  position: relative;
  margin-bottom: 10px;
  padding: 0 0 0 16px;
}

.page ul > li::before {
  position: absolute;
  top: 6px;
  left: 0;
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #003C9F;
}

.page ul > li ul li {
  padding: 0;
}

.page ul > li ul li::before {
  display: none;
}

.page ol {
  margin: 10px 0 30px 1em;
  list-style: none;
}

.page ol > li {
  position: relative;
  margin-bottom: 10px;
  padding: 0 0 0 22px;
}

.page ol > li::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  color: #003C9F;
}

.page ol > li:nth-child(1)::before {
  content: "1. ";
}

.page ol > li:nth-child(2)::before {
  content: "2. ";
}

.page ol > li:nth-child(3)::before {
  content: "3. ";
}

.page ol > li:nth-child(4)::before {
  content: "4. ";
}

.page ol > li:nth-child(5)::before {
  content: "5. ";
}

.page ol > li:nth-child(6)::before {
  content: "6. ";
}

.page ol > li:nth-child(7)::before {
  content: "7. ";
}

.page ol > li:nth-child(8)::before {
  content: "8. ";
}

.page ol > li:nth-child(9)::before {
  content: "9. ";
}

.page ol > li > ol {
  margin-left: 0;
}

.page ol > li > ol > li {
  position: relative;
  margin-bottom: 10px;
  padding: 0 0 0 26px;
}

.page ol > li > ol > li::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  color: #003C9F;
}

.page ol > li > ol > li:nth-child(1)::before {
  content: "(1)";
}

.page ol > li > ol > li:nth-child(2)::before {
  content: "(2)";
}

.page ol > li > ol > li:nth-child(3)::before {
  content: "(3)";
}

.page ol > li > ol > li:nth-child(4)::before {
  content: "(4)";
}

.page ol > li > ol > li:nth-child(5)::before {
  content: "(5)";
}

.page ol > li > ol > li:nth-child(6)::before {
  content: "(6)";
}

.page ol > li > ol > li:nth-child(7)::before {
  content: "(7)";
}

.page ol > li > ol > li:nth-child(8)::before {
  content: "(8)";
}

.page ol > li > ol > li:nth-child(9)::before {
  content: "(9)";
}

.page ol > li > ol > li > ol > li {
  position: relative;
  margin-bottom: 10px;
  padding: 0 0 0 22px;
}

.page ol > li > ol > li > ol > li::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  color: #003C9F;
}

.page ol > li > ol > li > ol > li:nth-child(1)::before {
  content: "①";
}

.page ol > li > ol > li > ol > li:nth-child(2)::before {
  content: "②";
}

.page ol > li > ol > li > ol > li:nth-child(3)::before {
  content: "③";
}

.page ol > li > ol > li > ol > li:nth-child(4)::before {
  content: "④";
}

.page ol > li > ol > li > ol > li:nth-child(5)::before {
  content: "⑤";
}

.page ol > li > ol > li > ol > li:nth-child(6)::before {
  content: "⑥";
}

.page ol > li > ol > li > ol > li:nth-child(7)::before {
  content: "⑦";
}

.page ol > li > ol > li > ol > li:nth-child(8)::before {
  content: "⑧";
}

.page ol > li > ol > li > ol > li:nth-child(9)::before {
  content: "⑨";
}

/*--------------------------------------------------------
  フォーム
----------------------------------------------------------*/
@media screen and (max-width: 599px) {
  .form-table tr {
    margin-bottom: 1.5em;
  }
}

.form-table tr th {
  width: 30%;
  padding: 20px 50px 20px 20px;
}

@media screen and (max-width: 599px) {
  .form-table tr th {
    display: block;
    width: auto;
    padding: 5px 10px;
  }
}

@media screen and (max-width: 599px) {
  .form-table tr td {
    display: block;
    width: auto;
  }
}

.form-table input[type=text] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0 10px 4px 0;
  padding: 8px;
  border: 1px solid #ccc;
  background: #FFE5E5;
  font-size: 14px;
}

.form-table .checkbox {
  font-size: 14px;
}

.form-table textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  background: #FFE5E5;
  font-size: 14px;
}

.form-table select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  margin: 0 0 4px 0;
  border: 1px solid #ccc;
  font-size: 14px;
}

.form-table a {
  color: #003C9F;
  text-decoration: underline;
}

.form-table ul li {
  padding-left: 0;
}

.form-table ul li::before {
  display: none;
}

.form-s {
  width: 24% !important;
}

.form-m {
  width: 36% !important;
}

.form-l {
  width: 90%;
}

@media screen and (max-width: 599px) {
  .form-l {
    width: 100%;
  }
}

.required {
  float: right;
  display: inline-block;
  padding: 1px 8px;
  background: #003C9F;
  color: #fff;
  font-size: 12px;
}

.optional {
  float: right;
  display: inline-block;
  padding: 1px 8px;
  background: #999;
  color: #fff;
  font-size: 12px;
}

.submit {
  margin: 60px 0;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .submit {
    margin: 30px 0;
  }
}

.submit .form-submit {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  display: inline-block;
  margin: 10px 0;
  padding: 10px 100px;
  border: 1px solid #fff;
  background: #FC4600;
  color: #fff;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 599px) {
  .submit .form-submit {
    font-size: 14px;
    font-size: 3.73333vw;
    display: block;
    width: 100%;
    padding: 10px;
    text-align: center;
  }
}

.submit .form-submit::before {
  position: absolute;
  left: -28px;
  top: 50%;
  content: "";
  display: block;
  width: 57px;
  height: 1px;
  background: #fff;
  transition: .3s;
}

.submit .form-submit::after {
  position: absolute;
  left: 23px;
  top: 50%;
  content: "";
  display: block;
  width: 9px;
  height: 1px;
  margin-top: -3px;
  background: #fff;
  transform: rotate(45deg);
  transition: .3s;
}

@media screen and (min-width: 960px) {
  .submit .form-submit:hover {
    text-decoration: none;
  }
  .submit .form-submit:hover::before {
    width: 120px;
    transition: .3s;
  }
  .submit .form-submit:hover::after {
    left: 86px;
    transition: .3s;
  }
}

.mail-description {
  margin-bottom: 30px;
}

@media screen and (max-width: 599px) {
  .mail-description {
    margin-bottom: 10px;
  }
}

.mail-description span {
  color: #003C9F;
}

.mail-before-attachment {
  margin-right: 10px;
}

.mail-after-attachment {
  margin-right: 10px;
}

#flashMessage {
  margin-bottom: 30px;
}

@media screen and (max-width: 599px) {
  #flashMessage {
    margin-bottom: 10px;
  }
}

.alert-message {
  color: #c00;
}

.form-back {
  margin-top: 50px;
}

/*--------------------------------------------------------
  メインイメージ
----------------------------------------------------------*/
.mainimage {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.mainimage video {
  min-width: 100%;
  height: 100%;
  min-height: 800px;
  vertical-align: top;
}

@media screen and (max-width: 599px) {
  .mainimage video {
    position: relative;
    left: -330px;
    min-width: auto;
    min-height: auto;
    width: auto;
    height: 100vh;
  }
}

.mainimage__copy {
  position: absolute;
  top: 350px;
  left: 0;
  z-index: 100;
  width: 100%;
  color: #fff;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
  text-align: center;
  text-shadow: 1px 1px 1px #231815;
  opacity: 0;
  z-index: 10;
}

@media screen and (max-width: 599px) {
  .mainimage__copy {
    font-size: 18px;
    font-size: 4.8vw;
    top: 40vh;
    right: 20px;
    padding: 10px 0 10px 20px;
    margin-top: 0;
  }
}

.mainimage img {
  width: 100%;
  vertical-align: bottom;
}

@keyframes maincopy {
  0% {
    opacity: 0;
    transform: transrateY(0);
  }
  5% {
    opacity: 1;
    transform: translateY(-70px);
  }
  23% {
    opacity: 1;
    transform: translateY(-70px);
  }
  25% {
    opacity: 0;
  }
}

.mainimage__copy {
  -moz-animation: maincopy 20s infinite;
  -webkit-animation: maincopy 20s infinite;
  animation: maincopy 20s infinite;
}

.mainimage__copy--01 {
  animation-delay: 0s;
}

.mainimage__copy--02 {
  animation-delay: 5s;
}

.mainimage__copy--03 {
  animation-delay: 10s;
}

.mainimage__copy--04 {
  animation-delay: 15s;
}

.mv {
  height: 90vh;
}

@media screen and (max-width: 599px) {
  .mv {
    height: 90vh;
  }
}

.mv-content {
  position: absolute;
  top: 0;
  left: 0;
}

/*--------------------------------------------------------
	トップページ
----------------------------------------------------------*/
.top-index {
  position: relative;
  margin-bottom: 80px;
  padding-top: 30px;
  background: url(../img/top/bg_paper.png);
  z-index: 1;
}

@media screen and (max-width: 599px) {
  .top-index {
    padding-top: 0;
  }
}

.top-index::before {
  position: absolute;
  top: -42px;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 42px;
  background: url(../img/top/bg_giza.png);
}

.top-index__scissor {
  position: absolute;
  top: -60px;
  left: 20px;
  transform: rotate(-8deg);
}

@media screen and (max-width: 599px) {
  .top-index__scissor {
    top: -45px;
    width: 45px;
    height: 28px;
  }
}

.top-service {
  position: relative;
  margin-bottom: 70px;
  padding-top: 30px;
}

@media screen and (max-width: 599px) {
  .top-service {
    text-align: right;
  }
}

.top-service__box {
  position: absolute;
  top: 50%;
  right: 0;
  width: 510px;
  margin-top: -220px;
  padding: 100px 80px;
  background: #FC4600;
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .top-service__box {
    position: relative;
    display: inline-block;
    width: 300px;
    margin-top: -30px;
    padding: 50px 40px;
  }
}

.top-service__box::after {
  position: absolute;
  bottom: -100px;
  left: 0;
  display: block;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 510px 100px 0;
  border-color: transparent rgba(0, 0, 0, 0.4) transparent transparent;
}

@media screen and (max-width: 599px) {
  .top-service__box::after {
    bottom: -60px;
    border-width: 0 300px 60px 0;
  }
}

@media screen and (max-width: 599px) {
  .top-service__box__wrapper {
    text-align: right;
  }
}

.top-service__title {
  position: relative;
  margin-bottom: 20px;
  font-family: "Josefin Slab", serif;
  font-size: 62px;
  font-weight: normal;
  line-height: 1.2;
}

@media screen and (max-width: 599px) {
  .top-service__title {
    font-size: 39px;
    font-size: 10.4vw;
  }
}

.top-service__title::before {
  position: absolute;
  top: -100px;
  left: 50%;
  content: "";
  display: block;
  width: 1px;
  height: 50px;
  background: #fff;
}

@media screen and (max-width: 599px) {
  .top-service__title::before {
    top: -50px;
    height: 25px;
  }
}

.top-service__title::after {
  position: absolute;
  top: -50px;
  left: 50%;
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  border-radius: 50%;
  background: #fff;
}

@media screen and (max-width: 599px) {
  .top-service__title::after {
    top: -25px;
  }
}

.top-service__image img {
  position: relative;
  min-width: 70%;
}

.top-service__image__01 {
  position: relative;
}

.top-service__text {
  margin-bottom: 40px;
  line-height: 2.5;
}

.top-company {
  position: relative;
  margin-bottom: 70px;
  padding-bottom: 100px;
  overflow: hidden;
}

.top-company__box {
  position: absolute;
  top: 50%;
  left: 0;
  width: 510px;
  margin-top: -270px;
  padding: 100px 80px;
  background: #003C9F;
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .top-company__box {
    position: relative;
    width: 300px;
    margin-top: -30px;
    padding: 50px 40px;
  }
}

.top-company__box::after {
  position: absolute;
  bottom: -100px;
  left: 0;
  display: block;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 100px 510px 0 0;
  border-color: rgba(0, 0, 0, 0.4) transparent transparent transparent;
}

@media screen and (max-width: 599px) {
  .top-company__box::after {
    bottom: -60px;
    border-width: 60px 300px 0 0;
  }
}

.top-company__title {
  position: relative;
  margin-bottom: 20px;
  font-family: "Josefin Slab", serif;
  font-size: 62px;
  font-weight: normal;
  line-height: 1.2;
}

@media screen and (max-width: 599px) {
  .top-company__title {
    font-size: 39px;
    font-size: 10.4vw;
  }
}

.top-company__title::before {
  position: absolute;
  top: -100px;
  left: 50%;
  content: "";
  display: block;
  width: 1px;
  height: 50px;
  background: #fff;
}

@media screen and (max-width: 599px) {
  .top-company__title::before {
    top: -50px;
    height: 25px;
  }
}

.top-company__title::after {
  position: absolute;
  top: -50px;
  left: 50%;
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  border-radius: 50%;
  background: #fff;
}

@media screen and (max-width: 599px) {
  .top-company__title::after {
    top: -25px;
  }
}

.top-company__title span {
  display: block;
  font-size: 36px;
}

@media screen and (max-width: 599px) {
  .top-company__title span {
    font-size: 23px;
    font-size: 6.13333vw;
  }
}

.top-company__image {
  text-align: right;
}

.top-company__image img {
  min-width: 70%;
}

.top-company__text {
  margin-bottom: 40px;
  line-height: 2.5;
}

.top-recruit {
  position: relative;
}

@media screen and (max-width: 599px) {
  .top-recruit {
    background: url(../img/top/img_recruit.jpg) center top no-repeat;
    background-size: cover;
  }
}

.top-recruit__box {
  position: absolute;
  top: 50%;
  right: 50px;
  width: 510px;
  margin-top: -240px;
  padding: 100px 80px;
  color: #fff;
  text-align: center;
  text-shadow: 1px 1px 1px #231815;
}

@media screen and (max-width: 599px) {
  .top-recruit__box {
    position: static;
    width: auto;
    top: 0;
    margin-top: 0;
    padding: 80px 40px;
  }
}

.top-recruit__title {
  margin-bottom: 20px;
  font-family: "Josefin Slab", serif;
  font-size: 62px;
  font-weight: normal;
  line-height: 1.2;
}

@media screen and (max-width: 599px) {
  .top-recruit__title {
    font-size: 39px;
    font-size: 10.4vw;
  }
}

.top-recruit__text {
  margin-bottom: 40px;
  line-height: 2.5;
}

@media screen and (max-width: 599px) {
  .top-recruit__image {
    display: none;
  }
}

.top-recruit__image img {
  min-width: 100%;
}

.top-news {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 120px;
}

@media screen and (max-width: 599px) {
  .top-news {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.top-news__body a {
  color: #003C9F;
}

@media screen and (min-width: 960px) {
  .top-news__body a:hover {
    text-decoration: underline;
  }
}

.top-access {
  margin-bottom: 100px;
}

@media screen and (max-width: 599px) {
  .top-access {
    margin-bottom: 40px;
  }
}

.top-access__title {
  position: relative;
  margin-bottom: 10px;
  font-family: "Josefin Slab", serif;
  font-size: 36px;
  font-weight: normal;
  line-height: 1.2;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .top-access__title {
    font-size: 30px;
    font-size: 8vw;
  }
}

.top-access__title::before {
  position: absolute;
  top: -20px;
  left: 50%;
  content: "";
  display: block;
  width: 260px;
  height: 1px;
  margin-left: -130px;
  background: #231815;
}

@media screen and (max-width: 599px) {
  .top-access__title::before {
    width: 200px;
    margin-left: -100px;
  }
}

.top-access__address {
  margin-bottom: 30px;
  text-align: center;
}

/*--------------------------------------------------------
  固定ページ共通
----------------------------------------------------------*/
/* メインビジュアル */
.page-mv__inner {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: table;
  height: 80vh;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
}

@media screen and (max-width: 599px) {
  .page-mv__inner {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 599px) {
  .page-mv__inner {
    width: 100%;
  }
}

.page-mv__wrap {
  display: table-cell;
  vertical-align: middle;
}

.page-mv__title {
  margin-bottom: 30px;
  font-family: "Josefin Slab", serif;
  font-size: 62px;
  font-weight: normal;
  vertical-align: middle;
  line-height: 1.2;
}

@media screen and (max-width: 599px) {
  .page-mv__title {
    font-size: 36px;
    font-size: 9.6vw;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 599px) {
  .page-mv__text {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.page-mv--default {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 120px;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .page-mv--default {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 599px) {
  .page-mv--default {
    padding-top: 60px;
  }
}

.page-mv--service {
  background: url(../img/page/mv_service.jpg) center no-repeat;
  background-size: cover;
}

.page-mv--service .page-mv__title {
  text-align: center;
}

.page-mv--service .page-mv__text {
  font-size: 20px;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .page-mv--service .page-mv__text {
    font-size: 14px;
    font-size: 3.73333vw;
  }
}

.page-mv--company {
  background: url(../img/page/mv_company.jpg) center no-repeat;
  background-size: cover;
}

.page-mv--company .page-mv__title {
  color: #000;
  text-align: left;
  text-shadow: 1px 1px 1px #fff;
}

.page-mv--company .page-mv__text {
  text-align: right;
}

.page-mv--recruit {
  background: url(../img/page/mv_recruit.jpg) center no-repeat;
  background-size: cover;
}

.page-mv--recruit .page-mv__title {
  text-align: right;
}

.page-mv--recruit .page-mv__text {
  text-align: right;
  color: #000;
  text-shadow: 1px 1px 0 #fff;
}

/* サブページ共通 */
.page p {
  margin-bottom: 30px;
}

@media screen and (max-width: 599px) {
  .page p {
    margin-bottom: 20px;
  }
}

.page .section {
  margin-bottom: 60px;
}

@media screen and (max-width: 599px) {
  .page .section {
    margin-bottom: 30px;
  }
}

.page .copy {
  color: #003C9F;
  font-size: 30px;
  font-weight: bold;
}

@media screen and (max-width: 599px) {
  .page .copy {
    font-size: 18px;
    font-size: 4.8vw;
  }
}

@keyframes up {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes down {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media screen and (min-width: 960px) {
  .anime-up {
    display: inline-block;
    -moz-animation: up 1s ease 2s;
    -webkit-animation: up 1s ease 2s;
    animation: up 1s ease 2s;
  }
}

@media screen and (min-width: 960px) {
  .anime-down {
    display: inline-block;
    -moz-animation: down 1s ease 2s;
    -webkit-animation: down 1s ease 2s;
    animation: down 1s ease 2s;
  }
}

/*--------------------------------------------------------
  SERVICE
----------------------------------------------------------*/
.service-about .title-custom, .service-buy .title-custom, .service-rental .title-custom {
  font-size: 32px;
}

@media screen and (max-width: 599px) {
  .service-about .title-custom, .service-buy .title-custom, .service-rental .title-custom {
    font-size: 26px;
    font-size: 6.93333vw;
  }
}

.service-about {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 10a0px;
  margin-bottom: 60px;
}

@media screen and (max-width: 599px) {
  .service-about {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.service-about__logo {
  width: 320px;
  margin: 0 auto 30px;
}

@media screen and (max-width: 599px) {
  .service-about__logo {
    width: 60%;
  }
}

.service-about__body {
  margin-bottom: 40px;
}

.service-about__body p {
  margin-bottom: 20px;
}

.page-sv {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 599px) {
  .page-sv {
    display: block;
    width: auto;
  }
}

.page-sv__box {
  position: relative;
  width: 360px;
  background: #000;
  color: #fff;
}

@media screen and (max-width: 599px) {
  .page-sv__box {
    width: 300px;
    text-align: left;
  }
}

.page-sv__box::after {
  position: absolute;
  bottom: -100px;
  left: 0;
  display: block;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
}

@media screen and (max-width: 599px) {
  .page-sv__box::after {
    bottom: -60px;
  }
}

.page-sv__title {
  position: relative;
  display: inline-block;
  margin-bottom: 40px;
  padding-bottom: 20px;
  font-family: "Josefin Slab", serif;
  font-size: 62px;
  font-weight: normal;
  line-height: 1.2;
}

@media screen and (max-width: 599px) {
  .page-sv__title {
    font-size: 36px;
    font-size: 9.6vw;
    padding-bottom: 15px;
  }
}

.page-sv__title::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  display: block;
  width: 120px;
  height: 1px;
  background: #FC4600;
}

.page-sv__image {
  width: calc(100vw - 360px);
}

@media screen and (max-width: 599px) {
  .page-sv__image {
    width: auto;
  }
}

.page-sv__image img {
  width: 100%;
}

.page-sv__text {
  font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 26px;
  line-height: 1.5;
}

@media screen and (max-width: 599px) {
  .page-sv__text {
    font-size: fz_vw(20);
  }
}

.page-sv__text span {
  font-size: 36px;
}

@media screen and (max-width: 599px) {
  .page-sv__text span {
    font-size: fz_vw(24);
  }
}

.page-sv--buy {
  flex-direction: row-reverse;
}

.page-sv--buy .page-sv__box {
  padding: 140px 0 0 60px;
}

@media screen and (max-width: 599px) {
  .page-sv--buy .page-sv__box {
    position: relative;
    top: -60px;
    padding: 50px 0 50px 50px;
  }
}

.page-sv--buy .page-sv__box::after {
  border-width: 100px 360px 0 0;
  border-color: rgba(0, 0, 0, 0.4) transparent transparent transparent;
}

@media screen and (max-width: 599px) {
  .page-sv--buy .page-sv__box::after {
    border-width: 60px 300px 0 0;
  }
}

@media screen and (max-width: 599px) {
  .page-sv--rental {
    text-align: right;
  }
}

.page-sv--rental .page-sv__image {
  position: relative;
  z-index: 20;
}

.page-sv--rental .page-sv__box {
  padding: 80px 0 0 60px;
  position: relative;
  z-index: 50;
}

@media screen and (max-width: 599px) {
  .page-sv--rental .page-sv__box {
    position: relative;
    top: -60px;
    display: inline-block;
    padding: 50px 0 50px 50px;
  }
}

.page-sv--rental .page-sv__box::after {
  border-width: 0 360px 100px 0;
  border-color: transparent rgba(0, 0, 0, 0.4) transparent transparent;
}

@media screen and (max-width: 599px) {
  .page-sv--rental .page-sv__box::after {
    border-width: 0 300px 60px 0;
  }
}

.service-buy {
  margin-bottom: 60px;
}

.service-buy__logo {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 40px;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .service-buy__logo {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.service-buy__logo img {
  width: 300px;
}

@media screen and (max-width: 599px) {
  .service-buy__logo img {
    width: 50%;
  }
}

.service-buy__body {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 40px;
}

@media screen and (max-width: 599px) {
  .service-buy__body {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.service-buy__body p {
  margin-bottom: 20px;
}

.service-rental__body {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 40px;
}

@media screen and (max-width: 599px) {
  .service-rental__body {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.service-rental__body p {
  margin-bottom: 20px;
}

.service-rental__body p a {
  color: #003C9F;
  text-decoration: underline;
}

/*--------------------------------------------------------
  COMPANY
----------------------------------------------------------*/
@media screen and (max-width: 599px) {
  .company-history .title-custom, .company-profile .title-custom {
    font-size: 26px;
    font-size: 6.93333vw;
  }
}

.company-history {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
}

@media screen and (max-width: 599px) {
  .company-history {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.company-history__box {
  padding: 0 100px;
}

@media screen and (max-width: 599px) {
  .company-history__box {
    padding: 0;
  }
}

.company-history__box__inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}

.company-history__maruichi {
  position: relative;
  width: 50%;
  padding: 40px 10px 40px 0;
  border-right: 1px solid #ccc;
}

@media screen and (max-width: 599px) {
  .company-history__maruichi {
    padding: 40px 10px 20px 0;
  }
}

.company-history__maruichi::before {
  position: absolute;
  top: 0;
  right: -7px;
  display: block;
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ccc;
}

.company-history__maruichi::after {
  position: absolute;
  bottom: 3px;
  right: -9px;
  display: block;
  content: '';
  width: 12px;
  height: 1px;
  background: #ccc;
  transform: rotate(-60deg);
}

.company-history__maruichi__title {
  font-size: 12px;
  position: relative;
  padding: 8px 0;
  background: #003C9F;
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .company-history__maruichi__title {
    font-size: 12px;
    font-size: 3.2vw;
  }
}

.company-history__maruichi__title::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 18.5px 0 18.5px 10px;
  border-color: transparent transparent transparent #fff;
}

.company-history__maruichi__title::after {
  position: absolute;
  top: 0;
  right: -15px;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 18.5px 0 18.5px 15px;
  border-color: transparent transparent transparent #003C9F;
}

.company-history__maruichi__table {
  padding: 40px 30px 0 0;
  text-align: right;
}

@media screen and (max-width: 599px) {
  .company-history__maruichi__table {
    padding: 30px 10px 0 0;
  }
}

.company-history__maruichi__table b {
  display: block;
}

.company-history__maruichi__table p {
  margin-bottom: 20px;
}

.company-history__quest {
  width: 50%;
  padding: 40px 0 40px 10px;
}

@media screen and (max-width: 599px) {
  .company-history__quest {
    padding: 40px 0 20px 10px;
  }
}

.company-history__quest__title {
  font-size: 12px;
  position: relative;
  top: 20px;
  padding: 8px 0;
  background: #FC4600;
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .company-history__quest__title {
    font-size: 12px;
    font-size: 3.2vw;
  }
}

.company-history__quest__title::before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 18.5px 10px 18.5px 0;
  border-color: transparent #fff transparent transparent;
}

.company-history__quest__title::after {
  position: absolute;
  top: 0;
  left: -15px;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 18.5px 15px 18.5px 0;
  border-color: transparent #FC4600 transparent transparent;
}

.company-history__quest__table {
  margin-top: 100px;
  padding: 40px 0 0 30px;
}

@media screen and (max-width: 599px) {
  .company-history__quest__table {
    padding: 30px 0  10px;
  }
}

.company-history__quest__table b {
  display: block;
}

.company-history__quest__table p {
  margin-bottom: 20px;
}

.company-history__merge {
  margin-bottom: 20px;
  text-align: center;
}

.company-history__merge p {
  font-size: 16px;
}

@media screen and (max-width: 599px) {
  .company-history__merge p {
    font-size: 14px;
    font-size: 3.73333vw;
  }
}

.company-history__merge p b {
  display: block;
}

.company-history__now {
  text-align: center;
}

.company-ceo {
  margin-bottom: 60px;
}

.company-ceo__sv {
  position: relative;
  margin-bottom: 50px;
}

.company-ceo__sv__image img {
  width: 100%;
}

.company-ceo__sv__title {
  position: absolute;
  right: 180px;
  bottom: 100px;
  font-family: "Josefin Slab", serif;
  font-size: 62px;
  font-weight: normal;
  line-height: 1.2;
  text-shadow: 1px 1px 1px #fff;
}

@media screen and (max-width: 599px) {
  .company-ceo__sv__title {
    font-size: 36px;
    font-size: 9.6vw;
    right: 30px;
    bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.company-ceo__inner {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  line-height: 2.2;
}

@media screen and (max-width: 599px) {
  .company-ceo__inner {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.company-ceo__inner p {
  margin-bottom: 30px;
}

.company-ceo__name {
  font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 35px;
}

.company-ceo__background {
  margin: 50px 0;
  padding-left: 50px;
  border-left: 1px solid #003C9F;
}

@media screen and (max-width: 599px) {
  .company-ceo__background {
    padding-left: 30px;
  }
}

.company-ceo__background table {
  border: 0;
}

.company-ceo__background table tr {
  border: 0;
}

.company-ceo__background table tr th {
  padding: 10px 0;
  border: 0;
  font-weight: normal;
}

@media screen and (max-width: 599px) {
  .company-ceo__background table tr th {
    padding-bottom: 0;
    border-bottom: 1px solid #ccc;
  }
}

.company-ceo__background table tr td {
  padding: 10px 0;
}

@media screen and (max-width: 599px) {
  .company-ceo__background table tr td {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 599px) {
  .company-ceo__links {
    margin-left: 20px;
  }
}

.company-ceo__links a {
  color: #003C9F;
  text-decoration: underline;
}

.company-profile__inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .company-profile__inner {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 599px) {
  .company-profile__inner {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.company-profile__info {
  width: 48%;
}

@media screen and (max-width: 599px) {
  .company-profile__info {
    width: auto;
    margin-bottom: 30px;
  }
}

.company-profile__info table {
  border: 0;
}

.company-profile__info table tr {
  border: 0;
}

.company-profile__info table tr th {
  padding: 10px 25px 10px 0;
  border: 0;
  font-size: 16px;
  font-weight: normal;
}

@media screen and (max-width: 599px) {
  .company-profile__info table tr th {
    font-size: 13px;
    font-size: 3.46667vw;
    display: table-cell;
    width: auto;
    padding: 10px 10px 10px 0;
    white-space: nowrap;
  }
}

.company-profile__info table tr td {
  font-size: 16px;
  position: relative;
  padding: 10px 0 10px 30px;
  border: 0;
}

@media screen and (max-width: 599px) {
  .company-profile__info table tr td {
    font-size: 16px;
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 599px) {
  .company-profile__info table tr td {
    font-size: 13px;
    font-size: 3.46667vw;
    display: table-cell;
    padding: 10px 0 10px 20px;
  }
}

.company-profile__info table tr td::before {
  position: absolute;
  top: 10px;
  left: 0;
  content: "／";
}

.company-profile__map {
  width: 48%;
}

@media screen and (max-width: 599px) {
  .company-profile__map {
    width: auto;
  }
}

/*--------------------------------------------------------
  RECRUIT
----------------------------------------------------------*/
.recruit-message {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  font-size: 16px;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .recruit-message {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 599px) {
  .recruit-message {
    font-size: 14px;
    font-size: 3.73333vw;
  }
}

.recruit-requirements {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .recruit-requirements {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.recruit-requirements .title-custom {
  font-size: 40px;
}

@media screen and (max-width: 599px) {
  .recruit-requirements .title-custom {
    font-size: 36px;
    font-size: 9.6vw;
  }
}

.recruit-requirements__text {
  margin: 30px 0;
}

.recruit-requirements__text a {
  display: inline-block;
  padding: 0 5px;
  color: #003C9F;
  text-decoration: underline;
}

@media screen and (min-width: 960px) {
  .delighter.anime-left {
    transition: all .3s ease-out;
    transform: translateX(-5%);
    opacity: 0;
  }
  .delighter.anime-left.started {
    transform: none;
    opacity: 1;
  }
  .delighter.anime-right {
    transition: all .3s ease-out;
    transform: translateX(5%);
    opacity: 0;
  }
  .delighter.anime-right.started {
    transform: none;
    opacity: 1;
  }
  .delighter.anime01 {
    transition: all .6s ease-out;
    transform: translateY(10%);
    opacity: 0;
  }
  .delighter.anime01.started {
    transform: none;
    opacity: 1;
  }
  .delighter.anime02 {
    transition: all 1s ease-out;
    transform: translateY(20%);
  }
  .delighter.anime02.started {
    transform: none;
  }
  .delighter.anime03 {
    transition: all .6s ease-out;
    transform: translateY(30%);
    opacity: 0;
  }
  .delighter.anime03.started {
    transform: none;
    opacity: 1;
  }
  .delighter.top-index__scissor {
    transition: all .5s ease-out;
    transform: none;
  }
  .delighter.top-index__scissor.started {
    transform: rotate(-30deg);
  }
  .delighter.top-index__scissor.started.ended {
    transform: rotate(710deg);
  }
}
