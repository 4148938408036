@charset "utf-8";

/*--------------------------------------------------------
  ヘッダー
----------------------------------------------------------*/

/* ヘッダー */
.header {
  @include clearfix();
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  border-bottom: 1px solid #ccc;
  background: rgba(255,255,255,0.7);
  transition: .5s;
  &.hide {
    transform: translateY(-100%);
  }
  &__inner {
    @include base-wrap();
  }
  &__logo {
    float: left;
    margin: 30px 0;
    @include mq(sp) {
      float: none;
      margin: 14px 0 14px 50px;
      text-align: center;
    }
    h1 {
      display: inline;
    }
    img {
      width: 80%;
      height: 18px;
    }
  }
}
.gnavi {
  float: right;
  @include mq(sp) {
    position: fixed;
    top: 0;
    left: -280px;
    float: none;
    height: 100%;
    background: #333;
    z-index: 800;
  }
  &__list {
    @include clearfix();
    @include mq(sp) {
      padding-top: 80px;
    }
    &__item {
      float: left;
      @include mq(sp) {
        float: none;
      }
      a {
        display: block;
        padding: 30px 26px;
        font-family: $font-josefin;
        @include mq(sp) {
          padding: 15px 30px;
          color: #fff;
        }
        &:hover {
          @include mq(pc) {
            background: #ccc;
          }
        }
      }
    }
  }
}