@charset "utf-8";

/*--------------------------------------------------------
  COMPANY
----------------------------------------------------------*/
.company-history, .company-profile {
  .title-custom {
    @include mq(sp) {
      @include fz_vw(26);
    }
  }
}
.company-history {
  @include base-wrap--space();
  margin-bottom: 60px;
  &__box {
    padding: 0 100px;
    @include mq(sp) {
      padding: 0;
    }
    &__inner {
      @include flex-sp();
      margin-bottom: 20px;
    }
  }
  &__maruichi {
    position: relative;
    width: 50%;
    padding: 40px 10px 40px 0;
    border-right: 1px solid #ccc;
    @include mq(sp) {
      padding: 40px 10px 20px 0;
    }
    &::before {
      position: absolute;
      top: 0;
      right: -7px;
      display: block;
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #ccc;
    }
    &::after {
      position: absolute;
      bottom: 3px;
      right: -9px;
      display: block;
      content: '';
      width: 12px;
      height: 1px;
      background: #ccc;
      transform: rotate(-60deg);
    }
    &__title {
      @include fs(12);
      position: relative;
      padding: 8px 0;
      background: $main;
      color: #fff;
      text-align: center;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 18.5px 0 18.5px 10px;
        border-color: transparent transparent transparent #fff;
      }
      &::after {
        position: absolute;
        top: 0;
        right: -15px;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 18.5px 0 18.5px 15px;
        border-color: transparent transparent transparent $main;
      }
    }
    &__table {
      padding: 40px 30px 0 0;
      text-align: right;
      @include mq(sp) {
        padding: 30px 10px 0 0;
      }
      b {
        display: block;
      }
      p {
        margin-bottom: 20px;
      }
    }
  }
  &__quest {
    width: 50%;
    padding: 40px 0 40px 10px;
    @include mq(sp) {
      padding: 40px 0 20px 10px;
    }
    &__title {
      @include fs(12);
      position: relative;
      top: 20px;
      padding: 8px 0;
      background: $sub;
      color: #fff;
      text-align: center;
      &::before {
        position: absolute;
        top: 0;
        right: 0;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 18.5px 10px 18.5px 0;
        border-color: transparent #fff transparent transparent;
      }
      &::after {
        position: absolute;
        top: 0;
        left: -15px;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 18.5px 15px 18.5px 0;
        border-color: transparent $sub transparent transparent;
      }
    }
    &__table {
      margin-top: 100px;
      padding: 40px 0 0 30px;
      @include mq(sp) {
        padding: 30px 0  10px;
      }
      b {
        display: block;
      }
      p {
        margin-bottom: 20px;
      }
    }
  }
  &__merge {
    margin-bottom: 20px;
    text-align: center;
    p {
      font-size: 16px;
      @include mq(sp) {
        @include fz_vw(14);
      }
      b {
        display: block;
      }
    }
  }
  &__now {
    text-align: center;
  }
}
.company-ceo {
  margin-bottom: 60px;
  &__sv {
    position: relative;
    margin-bottom: 50px;
    &__image {
      img {
        width: 100%;
      }
    }
    &__title {
      position: absolute;
      right: 180px;
      bottom: 100px;
      font-family: $font-josefin;
      font-size: 62px;
      font-weight: normal;
      line-height: 1.2;
      text-shadow: 1px 1px 1px #fff;
      @include mq(sp) {
        @include fz_vw(36);
        right: 30px;
        bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  &__inner {
    @include base-wrap--space();
    line-height: 2.2;
    p {
      margin-bottom: 30px;
    }
  }
  &__name {
    font-family: $mincho;
    font-size: 35px;
  }
  &__background {
    margin: 50px 0;
    padding-left: 50px;
    border-left: 1px solid $main;
    @include mq(sp) {
      padding-left: 30px;
    }
    table {
      border: 0;
      tr {
        border: 0;
        th {
          padding: 10px 0;
          border: 0;
          font-weight: normal;
          @include mq(sp) {
            padding-bottom: 0;
            border-bottom: 1px solid #ccc;
          }
        }
        td {
          padding: 10px 0;
          @include mq(sp) {
            padding-bottom: 30px;
          }
        }
      }
    }
  }
  &__links {
    @include mq(sp) {
      margin-left: 20px;
    }
    a {
      color: $main;
      text-decoration: underline;
    }
  }
}
.company-profile {
  &__inner {
    @include flex();
    @include base-wrap--space();
  }
  &__info {
    width: 48%;
    @include mq(sp) {
      width: auto;
      margin-bottom: 30px;
    }
    table {
      border: 0;
      tr {
        border: 0;
        th {
          padding: 10px 25px 10px 0;
          border: 0;
          font-size: 16px;
          font-weight: normal;
          @include mq(sp) {
            @include fz_vw(13);
            display: table-cell;
            width: auto;
            padding: 10px 10px 10px 0;
            white-space: nowrap;
          }
        }
        td {
          @include fs(16);
          position: relative;
          padding: 10px 0 10px 30px;
          border: 0;
          @include mq(sp) {
            @include fz_vw(13);
            display: table-cell;
            padding: 10px 0 10px 20px;
          }
          &::before {
            position: absolute;
            top: 10px;
            left: 0;
            content: "／";
          }
        }
      }
    }
  }
  &__map {
    width: 48%;
    @include mq(sp) {
      width: auto;
    }
  }
}