@charset "utf-8";

/*--------------------------------------------------------
	トップページ
----------------------------------------------------------*/
.top-index {
  position: relative;
  margin-bottom: 80px;
  padding-top: 30px;
  background: url(../img/top/bg_paper.png);
  z-index: 1;
  @include mq(sp) {
    padding-top: 0;
  }
  &::before {
    position: absolute;
    top: -42px;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 42px;
    background: url(../img/top/bg_giza.png);
  }
  &__scissor {
    position: absolute;
    top: -60px;
    left: 20px;
    transform: rotate(-8deg);
    @include mq(sp) {
      top: -45px;
      width: 45px;
      height: 28px;
    }
  }
}
.top-service {
  position: relative;
  margin-bottom: 70px;
  padding-top: 30px;
  @include mq(sp) {
    text-align: right;
  }
  &__box {
    position: absolute;
    top: 50%;
    right: 0;
    width: 510px;
    margin-top: -220px;
    padding: 100px 80px;
    background: $sub;
    color: #fff;
    text-align: center;
    @include mq(sp) {
      position: relative;
      display: inline-block;
      width: 300px;
      margin-top: -30px;
      padding: 50px 40px;
    }
    &::after {
      position: absolute;
      bottom: -100px;
      left: 0;
      display: block;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 510px 100px 0;
      border-color: transparent rgba(0,0,0,0.4) transparent transparent;
      @include mq(sp) {
        bottom: -60px;
        border-width: 0 300px 60px 0;
      }
    }
    &__wrapper {
      @include mq(sp) {
        text-align: right;
      }
    }
  }
  &__title {
    position: relative;
    margin-bottom: 20px;
    font-family: $font-josefin;
    font-size: 62px;
    font-weight: normal;
    line-height: 1.2;
    @include mq(sp) {
      @include fz_vw(39);
    }
    &::before {
      position: absolute;
      top: -100px;
      left: 50%;
      content: "";
      display: block;
      width: 1px;
      height: 50px;
      background: #fff;
      @include mq(sp) {
        top: -50px;
        height: 25px;
      }
    }
    &::after {
      position: absolute;
      top: -50px;
      left: 50%;
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      margin-left: -4px;
      border-radius: 50%;
      background: #fff;
      @include mq(sp) {
        top: -25px;
      }
    }
  }
  &__image {
    img {
      position: relative;
      min-width: 70%;
    }
    &__01 {
      position: relative;
    }
  }
  &__text {
    margin-bottom: 40px;
    line-height: 2.5;
  }
}
.top-company {
  position: relative;
  margin-bottom: 70px;
  padding-bottom: 100px;
  overflow: hidden;
  &__box {
    position: absolute;
    top: 50%;
    left: 0;
    width: 510px;
    margin-top: -270px;
    padding: 100px 80px;
    background: $main;
    color: #fff;
    text-align: center;
    @include mq(sp) {
      position: relative;
      width: 300px;
      margin-top: -30px;
      padding: 50px 40px;
    }
    &::after {
      position: absolute;
      bottom: -100px;
      left: 0;
      display: block;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 100px 510px 0 0;
      border-color: rgba(0,0,0,0.4) transparent transparent transparent;
      @include mq(sp) {
        bottom: -60px;
        border-width: 60px 300px 0 0;
      }
    }
  }
  &__title {
    position: relative;
    margin-bottom: 20px;
    font-family: $font-josefin;
    font-size: 62px;
    font-weight: normal;
    line-height: 1.2;
    @include mq(sp) {
      @include fz_vw(39);
    }
    &::before {
      position: absolute;
      top: -100px;
      left: 50%;
      content: "";
      display: block;
      width: 1px;
      height: 50px;
      background: #fff;
      @include mq(sp) {
        top: -50px;
        height: 25px;
      }
    }
    &::after {
      position: absolute;
      top: -50px;
      left: 50%;
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      margin-left: -4px;
      border-radius: 50%;
      background: #fff;
      @include mq(sp) {
        top: -25px;
      }
    }
    span {
      display: block;
      font-size: 36px;
      @include mq(sp) {
        @include fz_vw(23);
      }
    }
  }
  &__image {
    text-align: right;
    img {
      min-width: 70%;
    }
  }
  &__text {
    margin-bottom: 40px;
    line-height: 2.5;
  }
}
.top-recruit {
  position: relative;
  @include mq(sp) {
    background: url(../img/top/img_recruit.jpg) center top no-repeat;
    background-size: cover;
  }
  &__container {
  }
  &__box {
    position: absolute;
    top: 50%;
    right: 50px;
    width: 510px;
    margin-top: -240px;
    padding: 100px 80px;
    color: #fff;
    text-align: center;
    text-shadow: 1px 1px 1px $black;
    @include mq(sp) {
      position: static;
      width: auto;
      top: 0;
      margin-top: 0;
      padding: 80px 40px;
    }
  }
  &__title {
    margin-bottom: 20px;
    font-family: $font-josefin;
    font-size: 62px;
    font-weight: normal;
    line-height: 1.2;
    @include mq(sp) {
      @include fz_vw(39);
    }
  }
  &__text {
    margin-bottom: 40px;
    line-height: 2.5;
  }
  &__image {
    @include mq(sp) {
      display: none;
    }
    img {
      min-width: 100%;
    }
  }
}
.top-news {
  @include main-wrap--space();
  margin-bottom: 120px;
  &__body {
    a {
      color: $main;
      &:hover {
        @include mq(pc) {
          text-decoration: underline;
        }
      }
    }
  }
}
.top-access {
  margin-bottom: 100px;
  @include mq(sp) {
    margin-bottom: 40px;
  }
  &__title {
    position: relative;
    margin-bottom: 10px;
    font-family: $font-josefin;
    font-size: 36px;
    font-weight: normal;
    line-height: 1.2;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(30);
    }
    &::before {
      position: absolute;
      top: -20px;
      left: 50%;
      content: "";
      display: block;
      width: 260px;
      height: 1px;
      margin-left: -130px;
      background: $black;
      @include mq(sp) {
        width: 200px;
        margin-left: -100px;
      }
    }
  }
  &__address {
    margin-bottom: 30px;
    text-align: center;
  }
}
