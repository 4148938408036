@charset "utf-8";

/*--------------------------------------------------------
  固定ページ共通
----------------------------------------------------------*/

/* メインビジュアル */
.page-mv {
  &__inner {
    @include base-wrap();
    display: table;
    height: 80vh;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    @include mq(sp) {
      width: 100%;
    }
  }
  &__wrap {
    display: table-cell;
    vertical-align: middle;
  }
  &__title {
    margin-bottom: 30px;
    font-family: $font-josefin;
    font-size: 62px;
    font-weight: normal;
    vertical-align: middle;
    line-height: 1.2;
    @include mq(sp) {
      @include fz_vw(36);
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  &__text {
    @include mq(sp) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  &--default {
    @include base-wrap();
    padding-top: 120px;
    text-align: center;
    @include mq(sp) {
      padding-top: 60px;
    }
  }
  &--service {
    background: url(../img/page/mv_service.jpg) center no-repeat;
    background-size: cover;
    .page-mv__title {
      text-align: center;
    }
    .page-mv__text {
      font-size: 20px;
      text-align: center;
      @include mq(sp) {
        @include fz_vw(14);
      }
    }
  }
  &--company {
    background: url(../img/page/mv_company.jpg) center no-repeat;
    background-size: cover;
    .page-mv__title {
      color: #000;
      text-align: left;
      text-shadow: 1px 1px 1px #fff;
    }
    .page-mv__text {
      text-align: right;
    }
  }
  &--recruit {
    background: url(../img/page/mv_recruit.jpg) center no-repeat;
    background-size: cover;
    .page-mv__title {
      text-align: right;
    }
    .page-mv__text {
      text-align: right;
      color: #000;
      text-shadow: 1px 1px 0 #fff;
    }
  }
}



/* サブページ共通 */
.page {
  p {
    margin-bottom: 30px;
    @include mq(sp) {
      margin-bottom: 20px;
    }
  }
  .section {
    margin-bottom: 60px;
    @include mq(sp) {
      margin-bottom: 30px;
    }
  }
  .copy {
    color: $main;
    font-size: 30px;
    font-weight: bold;
    @include mq(sp) {
      @include fz_vw(18);
    }
  }
}

@keyframes up {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes down {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.anime-up {
  @include mq(pc) {
    display: inline-block;
    -moz-animation:up 1s ease 2s;
    -webkit-animation:up 1s ease 2s;
    animation:up 1s ease 2s;
  }
}
.anime-down {
  @include mq(pc) {
    display: inline-block;
    -moz-animation:down 1s ease 2s;
    -webkit-animation:down 1s ease 2s;
    animation:down 1s ease 2s;
  }
}